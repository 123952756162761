import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import TcVod from 'vod-js-sdk-v6'  //腾讯云
Vue.prototype.$TcVod = TcVod
// import axios from "axios";

import Print from "vue-print-nb";

Vue.use(Print);
// axios.defaults.withCredentials = true;
Vue.config.productionTip = false;
Vue.use(Antd);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
