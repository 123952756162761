// 获取登录信息，取多个值请用 "," 隔开
export function getUserInfo(string) {
    let user_info = JSON.parse(window.localStorage.getItem('user_info')) //获取本地用户登录信息
    if (!user_info) return {}
    if (!string || string === '') {return user_info} //设定默认返回完整用户信息对象
    let list = string.split(",") //分割多个参数
    let data = {}
    for (let i = 0; i < list.length; i++) { //将接受参数分割组成新的对象返回
        data[list[i]] = user_info[list[i]]
    }
    return data
}
// 保存登录信息
export function addUserInfo (type,data) {
    window.localStorage.setItem(type, JSON.stringify(data))
}