import Vue from 'vue'
import Vuex from 'vuex'
import {addUserInfo, getUserInfo} from "@/util/userInfo";
import {getPersonalData} from "@/service/api_fire";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  //  左侧列表id
    leftId:1,
  //  页码数
    pageNo:1,
  //  左侧列表type
    leftType:'',
    //  搜索标题
    titleVal:'',
    // 用户头像
    userImage: null,
    // 用户名字
    userName: '',
    // 用户学校/单位
    job_title: null,
    // 认证会员/非会员
    cert_status: '',
    phone: null,
    avatar: '',
    isLoginStatus:'',  //首页参与调研的状态
    contributeItem:{},      //单个投稿
    contributeType:0, //投稿是否修改
  },
  getters: {
  },
  mutations: {
    // 首页参与调研的状态
    isLoginStatus(state,data){
      state.isLoginStatus = data
    },
    //  左侧列表id
    leftId(state,value){
      state.leftId = value
    },
    //  页码数
    pageNo(state,value){
      state.pageNo = value
    },
  //  左侧列表type
    leftType(state,value){
      state.leftType = value
    },
    //  搜索标题
    titleVal(state,value){
      state.titleVal = value
    },
    async getUserData (state,value) { // 保存当前用户信息
      let { token } = getUserInfo('token') //调用写好的方法获取本地用户信息
      if (token === null || token === '' || !token) { //判断当前用户登陆状态
        this.$message.warning('无token，请检查登陆状态')
      }
      const response = await getPersonalData() // 获取当前用户资料
      let job_title
      if (response.data.type === 1001 || response.data.type === 1002 || response.data.type === 1003 || response.data.type === 1004) {
        job_title = response.data.hospital_name
      }else if (response.data.type === 1005){
        job_title = response.data.school
      }else if (response.data.type === 1006 || response.data.type === 1007) {
        job_title = response.data.work_unit
      }else {
        job_title = '未知'
      }
      if (response.code === 0) {
        state.userName = response.data.name
        state.avatar = response.data.avatar
        state.token = response.data.token
        state.job_title = job_title
        state.phone = response.data.tel
        state.cert_status = response.data.cert_status
        let data = {
          token,
          user_id:response.data.id,
          ...response.data
        }
        addUserInfo('user_info', data)
      }else {
        this.$message.warning(response.message)
      }
    }
  },
  actions: {
    //  左侧列表id
    leftId(context,value){
      context.commit("leftId",value)
    },
    //  页码数
    pageNo(context,value){
      context.commit("pageNo",value)
    },
    //  左侧列表type
    leftType(context,value){
      context.commit("leftType",value)
    },
    //  搜索标题
    titleVal(context,value){
      context.commit("titleVal",value)
    },
    // 获取微信接口
    getCode({ commit }, code) {
      console.log(code)
    },

  },
  modules: {
  }
})
