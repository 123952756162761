import Vue from "vue";
import VueRouter from "vue-router";
import { getUserInfo } from "@/util/userInfo";
import { message } from "ant-design-vue";

Vue.use(VueRouter);
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "WebRoot",
    component: () => import("../components/WebRoot"),
    children: [
      {
        path: "/",
        name: "Homepage",
        component: () => import("../views/content/Homepage"),
      },
      {
        path: "/download",
        name: "Download",
        component: () => import("../views/content/Download"),
      },
      {
        //忘记密码
        path: "/UpdatePwd",
        name: "UpdatePwd",
        component: () => import("../components/UpdatePwd.vue"),
      },
      {
        //年度协议确认资料
        path: "/ConfirmInfo",
        name: "ConfirmInfo",
        component: () => import("../components/ConfirmInfo.vue"),
      },
      {
        //年度协议
        path: "/ServiceAgreement",
        name: "ServiceAgreement",
        component: () => import("../components/ServiceAgreement.vue"),
      },
      {
        //投稿
        path: "/Contribute",
        name: "Contribute",
        component: () =>
          import("../views/content/ContributeView/Contribute.vue"),
      },
      {
        text: "参加投稿",
        path: "/SubmitAnArticle",
        name: "SubmitAnArticle",
        component: () => import("../views/case/SubmitAnArticle"),
      },
      // 点播
      {
        path: "/pointLive",
        name: "PointLive",
        component: () => import("../views/content/PointLive"),
      },
      {
        path: "/detailsDemand",
        name: "DetailsDemand",
        component: () => import("../views/content/DetailsDemand"),
      },
      {
        path: "/projectDetails",
        name: "ProjectDetails",
        component: () => import("../views/content/ProjectDetails"),
      },
      {
        path: "/helpCenter",
        name: "HelpCenter",
        component: () => import("../views/content/HelpCenter"),
      },
      {
        path: "/helpCenterContent",
        name: "HelpCenterContent",
        component: () => import("../views/content/HelpCenterContent"),
      },
      {
        path: "/testhh",
        name: "testhh",
        component: () => import("../views/case/test"),
      },
      {
        // 个人中心
        path: "/PersonalCenter",
        // name: 'PersonalCenter',
        component: () =>
          import("../views/content/PersonalCenter/PersonalCenter"),
        children: [
          {
            text: "个人资料",
            path: "/",
            name: "PersonalData",
            component: () =>
              import("../views/content/PersonalCenter/content/PersonalData"),
          },
          {
            text: "个人资料",
            path: "/test",
            name: "test",
            component: () =>
              import("../views/content/PersonalCenter/content/test"),
          },
          {
            text: "去认证",
            path: "/authentication",
            name: "authentication",
            component: () =>
              import("../views/content/PersonalCenter/content/authentication"),
          },
          {
            text: "我的会议",
            path: "/MyMeeting",
            name: "MyMeeting",
            component: () =>
              import("../views/content/PersonalCenter/content/MyMeeting"),
          },
          {
            text: "我的投稿",
            path: "/MyContribute",
            name: "MyContribute",
            component: () =>
              import("../views/content/ContributeView/MyContribute.vue"),
          },
          {
            text: "签署协议",
            path: "/MyProtocol",
            name: "MyProtocol",
            component: () =>
              import("../views/content/PersonalCenter/content/MyProtocol.vue"),
          },
          {
            text: "账号设置",
            path: "/PasswordEdit",
            name: "PasswordEdit",
            component: () =>
              import("../views/content/PersonalCenter/content/PasswordEdit"),
          },
          {
            text: "意见反馈",
            path: "/FeedBack",
            name: "FeedBack",
            component: () =>
              import("../views/content/PersonalCenter/content/FeedBack"),
          },
          {
            text: "病例夹",
            path: "/caseHome",
            name: "caseHome",
            component: () => import("../views/case/caseHome"),
          },
          {
            text: "急危重症视点",
            path: "/JwProject",
            name: "JwProject",
            component: () => import("../views/case/JwProject"),
          },
          // {
          //   text: '投诉',
          //   path: '/Complaint',
          //   name: 'Complaint',
          //   component: () => import('../views/content/PersonalCenter/content/Complaint')
          // }
        ],
      },
      {
        path: "/DrugSearch",
        name: "DrugSearch",
        component: () => import("../views/content/DrugSearch"),
      },
      {
        path: "/weijiRecord",
        name: "weijiRecord",
        component: () => import("../views/content/weijiRecord"),
      },
    ],
  },
  {
    path: "/InviteForm",
    name: "InviteForm",
    component: () => import("../views/case/InviteForm"),
  },
  // {
  //   path: '/testCode',
  //   name: 'test',
  //   component: () => import('../components/test.vue'),
  // },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (ab  out.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  // base: "/Offical/",
});
export default router;

router.beforeEach((to, form, next) => {
  if (
    to.path === "/PersonalCenter" ||
    to.path === "/Complaint" ||
    to.path === "/FeedBack" ||
    to.path === "/MyMeeting"
  ) {
    //判断目标页面
    let { token } = getUserInfo("token"); // 判断用户状态
    if (token) {
      next();
    } else {
      // message.warning('请先登录')
      router.push({ path: "/" });
    }
  } else {
    next();
  }
  // if (to.query.code) { // 判断是否微信扫码登录
  //   alert(to.query.code)
  //   router.app.$options.store.dispatch('getCode',
  //       to.query.code
  //   )
  // }else {
  //   console.log(11)
  // }
  // next()
});
