export function getOS() {
  let Os = "";
  let agent = navigator.userAgent.toLowerCase();
  let isWin = /windows/i.test(agent);
  if (isWin) {
    Os = "web_windows";
  } else {
    Os = "web_mac";
  }
  return Os;
}
